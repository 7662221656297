import React from "react";
import Container from "react-bootstrap/Container";

const Footer = (props) => {
  const bgStyle = { backgroundColor: "#f5f5f5" };

  return (
    <footer style={bgStyle} className="mt-auto pt-5 pb-3 text-center ">
		
      <Container>
        {props.children}
		<div style={{textAlign:'right'}}>
        <i className="fas fa-code" /> con <i className="fas fa-heart" /> por{" "}
        <a
          className="badge badge-dark"
          rel="noopener"
		  target=" _blank"
          href="https://github.com/kekoinomano"
          aria-label="My GitHub"
        >
          Sergio Álvarez
        </a>{" "}
        usando <i className="fab fa-react" />
		</div>
        
      </Container>
    </footer>
  );
};

export default Footer;
