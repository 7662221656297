import React from "react";
import { Jumbotron, Container } from "react-bootstrap";
import { useScrollPosition } from "../../hooks/useScrollPosition";

const Skills2 = React.forwardRef(
	(props, skillsTabRef) => {
//function Skills({ heading, hardSkills, softSkills }) {
  const [isScrolled, setIsScrolled] = React.useState(false);
  //const navbarDimensions = useResizeObserver(navbarMenuRef);
  useScrollPosition(
    ({ prevPos, currPos }) => {
      if (!isScrolled && currPos.y - 400 < 0) setIsScrolled(true);
    },
    [],
    skillsTabRef
  );
  const SKILLS_c = [
	{type: "HTML", level: "99"},
	{type: "CSS", level: "98"},
	{type: "PHP", level: "95"},
	{type: "MySQL", level: "93"},
	{type: "JavaScript", level: "87"},
	{type: "C/C++", level: "80"},
	{type: "React", level: "75"},
	{type: "Python", level: "70"},
  ];
  return (
    <Jumbotron ref={skillsTabRef} fluid className="bg-white m-0" id="skills">
      <Container className="p-5 ">
        <h2 ref={skillsTabRef} className="display-4 pb-5 text-center">
          Skills
        </h2>
        <div className={`container`}>
        <ul className="skills">
          {SKILLS_c.map((skill, index) => 
            <li className="bgstyle"
              key={skill.type}
              //style={{ width: `${skill.level}%`, backgroundColor: `hsl(300, 40%, ${100 / (index + 3.5) }%)` }}
			  style={{
				background: `linear-gradient(130deg, #4484ce, #1ad7c0, #ff9b11, #9b59b6, #ff7f7f, #9b59b6) 0% 0% / 1200% 1200%`,
				width: `${skill.level}%`
			  }}
            >
              <p>{skill.type}</p>
			  <p>{skill.level}</p>
            </li>
          )}
        </ul>
		<div id="stars"></div>
      </div>
      </Container>
    </Jumbotron>
	
  );
})

export default Skills2;
