import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useScrollPosition } from "../hooks/useScrollPosition";
import useResizeObserver from "../hooks/useResizeObserver";
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import { mainBody, repos, about, skills } from "../editable-stuff/config.js";

const Navigation = React.forwardRef((props, ref) => {
  // const { showBlog, FirstName } = config;
  const [isTop, setIsTop] = useState(true);
  const [isInit, setisInit] = useState(false);
  const [isProjects, setIsProjects] = useState(false);
  const [isAbout, setIsAbout] = useState(false);
  const [isActual, setIsActual] = useState(false);
  const [isSkills, setIsSkills] = useState(false);
  const [scrollPosition, setScrollPosition] = useState(0);
  const navbarMenuRef = React.useRef();
  const navbarDimensions = useResizeObserver(navbarMenuRef);
  const navBottom = navbarDimensions ? navbarDimensions.bottom : 0;
  useScrollPosition(
    ({ prevPos, currPos }) => {
      if (!navbarDimensions) return;
      currPos.y + ref.current.offsetTop - navbarDimensions.bottom > 5
        ? setIsTop(true)
        : setIsTop(false);
      setScrollPosition(currPos.y);
    },
    [navBottom]
  );

  React.useEffect(() => {
    if (!navbarDimensions) return;
	//console.log(scrollPosition);
    navBottom - scrollPosition >= ref.current.offsetTop
      ? setIsTop(false)
      : setIsTop(true);
	  scrollPosition > -props.about_ref.current.offsetTop 
      ? setisInit(true)
      : setisInit(false);
	  scrollPosition <= -props.about_ref.current.offsetTop && scrollPosition -20 > -props.proy_ref.current.offsetTop 
      ? setIsAbout(true)
      : setIsAbout(false);
	  scrollPosition-20 <= -props.proy_ref.current.offsetTop && scrollPosition -20 > -props.actual_ref.current.offsetTop
      ? setIsProjects(true)
      : setIsProjects(false);
	  scrollPosition-20 <= -props.actual_ref.current.offsetTop && scrollPosition -20 > -props.skill_ref.current.offsetTop
      ? setIsActual(true)
      : setIsActual(false);
	  scrollPosition -20 <= -props.skill_ref.current.offsetTop
      ? setIsSkills(true)
      : setIsSkills(false);
  }, [navBottom, navbarDimensions, ref, scrollPosition])

  return (
    <Navbar
      ref={navbarMenuRef}
      className={` fixed-top  ${
        !isTop ? "navbar-white" : "navbar-transparent"
      }`}
      expand="lg"
    >
      <Navbar.Brand className="brand" href={process.env.PUBLIC_URL + "/#home"}
	  style={{color: `${isInit ? "#363636" : "rgba(0, 0, 0, 0.5)"}`,
	  fontWeight: `${isInit ? "600" : "initial"}`}}
	  >
        {isInit ? ('<') : ('')}
		{`${mainBody.firstName}`}
		{isInit ? (' />') : ('')}
      </Navbar.Brand>
      <Navbar.Toggle aria-controls="basic-navbar-nav" className="toggler" />
      <Navbar.Collapse id="basic-navbar-nav">
        <Nav className="mr-auto">
          {/* {
            <Nav.Link className="nav-link lead">
              <Link to={process.env.PUBLIC_URL + "/blog"}>Blog</Link>
            </Nav.Link>
          } */}
		  {about.show && (
            <Nav.Link
              className="nav-link lead"
			  style={{color: `${isAbout ? "#363636" : "rgba(0, 0, 0, 0.5)"}`,
			  fontWeight: `${isAbout ? "600" : "initial"}`}}
              href={process.env.PUBLIC_URL + "/#aboutme"}
            >
			  {isAbout ? ('<') : ('')}
              Sobre mi
			  {isAbout ? (' />') : ('')}
            </Nav.Link>
          )}
            <Nav.Link
              className="nav-link lead"
			  style={{color: `${isProjects ? "#363636" : "rgba(0, 0, 0, 0.5)"}`,
			  fontWeight: `${isProjects ? "600" : "initial"}`}}
              href={process.env.PUBLIC_URL + "/#projects"}
            >
			  {isProjects ? ('<') : ('')}
              Proyectos
			  {isProjects ? (' />') : ('')}
            </Nav.Link>
		  {/*
          <Nav.Link
            className="nav-link lead"
            href={about.resume}
            target="_blank"
            rel="noreferrer noopener"
          >
            CV
          </Nav.Link>
		  */}
		  <Nav.Link
              className="nav-link lead"
			  style={{color: `${isActual ? "#363636" : "rgba(0, 0, 0, 0.5)"}`,
			  fontWeight: `${isActual ? "600" : "initial"}`}}
              href={process.env.PUBLIC_URL + "/#currently"}
            >
			  {isActual ? ('<') : ('')}
              Actualidad
			  {isActual ? (' />') : ('')}
            </Nav.Link>
            <Nav.Link
              className="nav-link lead"
			  style={{color: `${isSkills ? "#363636" : "rgba(0, 0, 0, 0.5)"}`,
			  fontWeight: `${isSkills ? "600" : "initial"}`}}
              href={process.env.PUBLIC_URL + "/#skills"}
            >
              {isSkills ? ('<') : ('')}
              Skills
			  {isSkills ? (' />') : ('')}
            </Nav.Link>
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  );
});

export default Navigation;
