import React from "react";


const Actual = React.forwardRef(
	({}, refActual) => {

 
  return (
    <div id="currently" className="jumbotron bgstyle jumbotron-fluid m-0" ref={refActual}
	style={{
		background: `linear-gradient(136deg, #4484ce, #1ad7c0, #ff9b11, #9b59b6, #ff7f7f, #9b59b6) 0% 0% / 1200% 1200%`,
		color: 'white'
	  }}>
		  
      <div className="container container-fluid">
        <div className="row">
          <div className="col-12 align-self-center">
		  <h2 className="display-4 mb-5 text-center" style={{textShadow: '2px 2px 4px #000000'}}>
                        En la actualidad
                    </h2>
            <p className="lead text-center" style={{textShadow: 'rgb(0 0 0) 2px 2px 2px',fontSize: '1.25em',fontWeight: '400'}}>
				A principios de 2022 estaba trabajando como programador full-stack en la empresa <a style={{textShadow:'none', fontSize:'1.2em', fontWeight:'500', color:'black'}} target="_blank" href="https://ssmas.com"> SemSeoyMas </a>. <br />
				En ella desarrollaba proyectos para gran variedad de clientes, la gran mayoría de ellos  en <i className="fab fa-php" />. 
			 <br/>
	  			Decidí dejar el trabajo tras unos meses para desarrollar <a style={{textShadow:'none', fontSize:'1.1em', fontWeight:'500', color:'black'}} target="_blank" href="https://woweb.es"> https://woweb.es</a>, una plataforma de creación automática de páginas webs que está comenzando a ver el mercado, en la cual he invertido mis últimos meses y sigo mejorando el código día tras día.<br/>
			 También estoy cursando el  <a style={{textShadow:'none', fontSize:'1.2em', fontWeight:'500', color:'black'}} href="https://42.fr/en/homepage/" target="_blank"> Campus 42 </a> en Málaga, en el que desarrollamos diferentes trabajos en C.
			 <br/>
			 A día de hoy estoy buscando oportunidades de Ingeniería de Software, desarrollo Back-end en PHP o estructura de datos.  
			 </p>
            
          </div>
        </div>
      </div>
    </div>
  );
});

export default Actual;
