import React from "react";

const GetInTouch = ({ heading, message, email }) => {
  return (
    <>
      <h2 className="display-4 pb-3 text-center">{heading}</h2>
      <p className="lead text-center pb-3">
        {message}
		<br /><br />
		<a href="tel:+34628688994" style={{fontSize: '20px',fontWeight: '600', color: 'black'}}>
			<i className="fab fa-whatsapp" style={{marginBottom: '10px', marginRight: '10px', color: '#33c633'}}></i>
			+34 628 688 994
		</a>
		<br/>
	<a href="mailto:sergioalvarezalcedo@gmail.com" style={{fontSize: '18px',fontWeight: '600', color: 'black'}}>
		<i className="fas fa-envelope-open" style={{marginRight: '10px', color: 'rgb(35 74 198)'}}></i> 
		sergioalvarezalcedo@gmail.com</a>
      </p>
    </>
  );
};

export default GetInTouch;
