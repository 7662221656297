import React from 'react';
import Col from "react-bootstrap/Col";
import {
    Container,
    Row,
    Jumbotron
} from "react-bootstrap";

class Card extends React.Component {
	
	constructor() {
		super();
	  this.state = {
		flipped: null,
	  }
	}
	flip() {
	  this.setState({
		flipped: this.state.flipped ? false : true
	  })
	}
	render() {
	  return (
		<div onClick={()=>this.flip()} className={'card shadow-lg' + (this.state.flipped !== null ? this.state.flipped ? ' flipped' : ' unflipped' : '')} style={{backgroundImage: `url(${this.props.backgroundImage})`}}>
		  <div className="card__face--front">
			<div className="card__center">
			  <img src={this.props.center} alt="logo" />
			</div>
			<div className="card__frame">            
			  <span className="card__title">{this.props.title}</span>
			  	<div className="pb-3 pl-3">
					Languages:{" "}
					{this.props.languages.map((language) =>
  						<a href="#" key={language} className="badge badge-light card-link">{language}</a>
					)}
    			</div>
			  <img className="card__flipIcon" src="https://d30y9cdsu7xlg0.cloudfront.net/png/267318-200.png" />
			</div>
		  </div>
		  <div
        style={{
          background: `linear-gradient(${parseInt(10 + Math.random() * (255 - 10))}deg, #4484ce, #1ad7c0, #ff9b11, #9b59b6, #ff7f7f, #9b59b6) 0% 0% / 1200% 1200%`,
		  color: 'white'
        }}
        className="card__face--back bg-transparent bgstyle">
			<div id="stars"></div>
			<span className="card__description" style={{textShadow: 'rgb(0 0 0 / 59%) 2px 2px 4px', display: 'flex',
    flexDirection: 'column', height: '100%', justifyContent: 'space-between', fontSize:'1.2em', paddingRight: '0'}}>{this.props.children}</span>< br />
			
			<span className="card__description" style={{textShadow: 'rgb(0 0 0 / 59%) 2px 2px 4px', display: 'flex',
    flexDirection: 'column', height: '100%', justifyContent: 'space-around', paddingLeft: '0'}}>
			  {!this.props.is_app ? (<a href={this.props.url} target=" _blank" className="btn enlace"
			style={{borderColor: "white",color: 'white'}}>
        		<i className="fa fa-link" />
				</a>) : ('')}
			  <a href={this.props.url_rep} target=" _blank" className="btn enlace"
			style={{borderColor: "white",color: 'white'}}>
        		<i className="fab fa-github" />
      		</a>
			  </span>
		  </div>
		</div>
	  )
	}
  }

const Proyectos = React.forwardRef(
	({},refProy) => {
    return (  
        <section className="section" id="projects" ref={refProy}>
            <Container>
                <Jumbotron fluid className="bg-white">
                    <h2 className="display-4 mb-5 text-center">
                        Proyectos recientes
                    </h2>
                    <Row>
						
					<Col md={6}>
							<Card title="Woweb- Create web in one-click" center="https://miportfolio.wakeapp.org/img/woweb.png" 
								languages={["PHP : 65%","JS : 25%","Python : 10%"]} url = "https://woweb.es/" url_rep="https://github.com/kekoinomano/woweb"  is_app={0}>
									
							<p>Mi último trabajo.<br />Software de creación automática de páginas webs.</p>
							
							<ul style={{paddingLeft: '10px'}}>
								<li>Realizada dede cero</li>
								<li>Uso de diversas apis para creación automática de subdominios</li>
								<li>Pagos mediante Stripe</li>
								<li>Full responsive</li>
							</ul>
							
							</Card>
						</Col>
						<Col md={6}>
							<Card title="Multi-player game" center="https://miportfolio.wakeapp.org/img/risas.jpg" 
								languages={["PHP : 62%","JS : 38%"]} url = "https://risas.wakeapp.org/" url_rep="https://github.com/kekoinomano/cards-against-humanity"  is_app={0}>
									
							<p>Juego online multijugador y multisala realizado con javascript y php.</p>
							
							<ul style={{paddingLeft: '10px'}}>
								<li>Realizada dede cero</li>
								<li>Conexión a base de datos</li>
								<li>Consultas XMLHttpRequest</li>
								<li>Multijugador y multisala</li>
							</ul>
							
							</Card>
						</Col>
						<Col md={6}>
							<Card title="JS and CSS 3D animation" center="https://miportfolio.wakeapp.org/img/mitfg.png" 
								languages={["JS : 60%","CSS : 30%","HTML : 10%"]} url = "https://tfg.wakeapp.org/" url_rep="https://github.com/kekoinomano/MyTFG" is_app={0}>
									
							<p>Web realizada para mi TFG.<br />Efectos de movimiento en css y javascript, y animaciones 3d.</p>
							
								<ul style={{paddingLeft: '10px'}}>
								<li>Realizada dede cero</li>
								<li>Animaciones 3d</li>
								<li>JS y CSS</li>
							</ul>
							
							</Card>
						</Col>
						<Col md={6}>
							<Card title="Maps JAVA App" center="https://miportfolio.wakeapp.org/img/app.png" 
								languages={["JAVA : 72%","PHP : 28%"]} url = "https://tfg.wakeapp.org/" url_rep="https://github.com/kekoinomano/planium"  is_app={1}>
									
							<p>Aplicación Java<br />Mi primera aplicación hecha con Java, incluyendo mapas y métodos de pago.</p>
							<ul style={{paddingLeft: '10px'}}>
								<li>Métodos de pago (Stripe)</li>
								<li>Google Maps Api</li>
								<li>Usuarios, chat y notificaciones</li>
							</ul>
							
							</Card>
						</Col>
						<Col md={6}>
							<Card title="Custom Wordpress Web " center="https://miportfolio.wakeapp.org/img/ruralbridge.png" 
								languages={["Wordpress : 85%","PHP : 15%"]} url = "https://cel.wakeapp.org/" url_rep="https://github.com/kekoinomano/energy-web"  is_app={0}>
									
							<p>Realizada en Wordpress para una consultora energética.<br /> Con pluggins desarrollados completamente desde cero.</p>
							<ul style={{paddingLeft: '10px'}}>
								<li>Realizada dede cero</li>
								<li>Creación de pluggin</li>
								<li>Woocomerce</li>
								<li>Actualicación de Stock mediante Excel</li>
							</ul>
							
							</Card>
						</Col>
						<Col md={6}>
							<Card title="My last Portfolio Template " center="https://miportfolio.wakeapp.org/img/portfolio.png" 
								languages={["HTML : 10%","CSS : 45%","JS : 45%"]} url = "https://portfolio.wakeapp.org/" url_rep="https://github.com/kekoinomano/first_portfolio" is_app={0}>
									
							<p>Mi portfolio anterior.<br />Creado completamente desde cero, usando animaciones con CSS y Javascript, y filtros con HTML y JS.</p>
							<ul style={{paddingLeft: '10px'}}>
								<li>Código realizado desde cero</li>
								<li>Animaciones CSS</li>
								<li>Filtro dinámico usando JS</li>
								<li>Creación de sistema multi-idioma</li>
							</ul>
							
							</Card>
						</Col>
  					</Row>
                </Jumbotron>
            </Container>
        </section>
    );
})
 
export default Proyectos;